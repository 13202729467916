import React from "react";
import { Form, Input, InputNumber, Button } from "antd";
const layout = {
  layout: "vertical",
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 24,
  },
};
/* eslint-disable no-template-curly-in-string */

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
/* eslint-enable no-template-curly-in-string */
class Hezuozixun extends React.PureComponent {
  onFinish(values) {
    console.log(values);
  }

  render() {
    return (
      <div
        id="hezuozixun"
        className="home-page-wrapper content13-wrapper l1et26tsxjf-editor_css"
        style={{
          "background-image":
            "linear-gradient(to bottom, rgba(253, 253, 253, 0.3) 0%, rgba(193, 218, 255, 0.3) 94.44%, white 100%)",
          "background-repeat": "repeat",
          "background-size": "auto",
        }}
      >
        <div class="title-wrapper">
          <h1
            name="title"
            class="title-h1"
            style={{ opacity: 1, transform: "translate(0px, 0px)" }}
          >
            <span>
              <p>合作咨询</p>
              <p style={{ fontSize: 14 }}>
                助力企业低成本智能化应用开发，携手共创未来，欢迎广大SaaS企业应用创业伙伴、ISV项目集成商和产品平台深度合作伙伴填写下列表单提交合作信息，我们将尽快与您取得联系
              </p>
            </span>
          </h1>

          <div
            name="content"
            class="title-content l1et2g41vuc-editor_css"
            style={{
              opacity: 1,
              transform: "translate(0px, 0px)",
              display: "flex",
              "justify-content": "center",
            }}
          >
            <Form
              {...layout}
              name="nest-messages"
              onFinish={this.onFinish}
              validateMessages={validateMessages}
              style={{ width: 600 }}
            >
              <Form.Item
                name={["user", "name"]}
                label="公司名称"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={["user", "email"]}
                label="联系人"
                rules={[
                  {
                    type: "email",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item name={["user", "age"]} label="需求说明">
                <Input.TextArea rows={4} />
              </Form.Item>
              <Form.Item name={["user", "website"]} label="手机号">
                <Input />
              </Form.Item>

              <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 2 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    background:
                      "linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%)",
                    "box-shadow": "0 8px 24px rgb(95 155 241 / 35%)",
                    "border-radius": 20,
                    border: "none",
                    padding: "0 42px",
                    "line-height": 40,
                    height: 40,
                  }}
                >
                  提交
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default Hezuozixun;
