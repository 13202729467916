import React from "react";
export const Nav30DataSource = {
  wrapper: { className: "header3 home-page-wrapper" },
  page: { className: "home-page" },
  logo: {
    className: "header3-logo l1epa0v521-editor_css",
    children:
      "https://vimiu.oss-cn-shanghai.aliyuncs.com/app/-39f918bb-acfd-48c3-970d-3092bed5f92d.png",
  },
  Menu: {
    className: "header3-menu",
    children: [
      {
        name: "item0",
        className: "header3-item",
        children: {
          href: "#",
          children: [
            {
              children: (
                <span>
                  <p>首页</p>
                </span>
              ),
              name: "text",
            },
          ],
        },
      },
      {
        name: "item1",
        className: "header3-item",
        children: {
          href: "#hezuozixun",
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>合作咨询</p>
                  </span>
                </span>
              ),
              name: "text",
            },
          ],
          target: "",
        },
      },
      {
        name: "item2",
        className: "header3-item",
        children: {
          href: "#",
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>产品介绍</p>
                  </span>
                </span>
              ),
              name: "text",
            },
          ],
          target: "",
        },
        subItem: [
          {
            name: "sub",
            className: "item-sub",
            children: {
              href: "https://meeting.yiren.tech/h5/index.html#/pages/home/pc",
              target: "_blank",
              className: "item-sub-item",
              children: [
                {
                  name: "image0",
                  className: "item-image",
                  children:
                    "https://vimiu.oss-cn-shanghai.aliyuncs.com/app/-1e83fc88-33bb-43bc-b5dc-7ec40df9df89.png",
                },
                {
                  name: "title",
                  className: "item-title",
                  children: "AI机器人会议",
                },
                {
                  name: "content",
                  className: "item-content",
                  children: "让您的会议更智能更高效",
                },
              ],
            },
          },
          {
            name: "sub0",
            className: "item-sub",
            children: {
              href: "https://aim.baidu.com/product/dc201ff7-7577-42fc-bfef-d7aa1ee2d86c",
              target: "_blank",
              className: "item-sub-item",
              children: [
                {
                  name: "image0",
                  className: "item-image",
                  children:
                    "https://vimiu.oss-cn-shanghai.aliyuncs.com/app/-8e619acf-eeb0-45ff-94f8-32d0a753e71a.png",
                },
                {
                  name: "title",
                  className: "item-title",
                  children: "睿企通",
                },
                {
                  name: "content",
                  className: "item-content",
                  children: "睿企通私域流量运营平台",
                },
              ],
            },
          },
          {
            name: "sub1",
            className: "item-sub",
            children: {
              href: "https://aim.baidu.com/product/48214ac7-e259-4d6b-a2a2-d5ae714525af",
              target: "_blank",
              className: "item-sub-item",
              children: [
                {
                  name: "image0",
                  className: "item-image",
                  children:
                    "https://vimiu.oss-cn-shanghai.aliyuncs.com/app/-bd586199-b1dd-4c47-b509-19b0ed44c6aa.png",
                },
                {
                  name: "title",
                  className: "item-title",
                  children: "智慧美业",
                },
                {
                  name: "content",
                  className: "item-content",
                  children: "一站式智慧美业服务，您想要的我们都有！",
                },
              ],
            },
          },
          {
            name: "sub2",
            className: "item-sub",
            children: {
              href: "https://aim.baidu.com/product/725dd140-7341-40d3-b897-5160396ee317",
              target: "_blank",
              className: "item-sub-item",
              children: [
                {
                  name: "image0",
                  className: "item-image",
                  children:
                    "https://vimiu.oss-cn-shanghai.aliyuncs.com/app/-3e4c886f-5be6-4a24-bb21-d2d17353bd06.png",
                },
                {
                  name: "title",
                  className: "item-title",
                  children: "AI超级会员",
                },
                {
                  name: "content",
                  className: "item-content",
                  children: "基于人脸智能分析技术研发的用于会员营销的解决方案",
                },
              ],
            },
          },
        ],
      },
      {
        name: "item21",
        className: "header3-item",
        children: {
          href: "#",
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>产品视频</p>
                  </span>
                </span>
              ),
              name: "text",
            },
          ],
          target: "",
        },
        subItem: [
          {
            name: "sub23",
            className: "item-sub",
            children: {
              href: "https://www.bilibili.com/video/BV1Xp4y1b7LQ",
              target: "_blank",
              className: "item-sub-item",
              children: [
                {
                  children: <p>机器人会议</p>,
                  name: "text",
                },
              ],
            },
          },
          {
            name: "sub24",
            className: "item-sub",
            children: {
              href: "https://www.bilibili.com/video/BV1uJ41137Xq",
              target: "_blank",
              className: "item-sub-item",
              children: [
                {
                  children: <p>智慧美业 门店助手</p>,
                  name: "text",
                },
              ],
            },
          },
          {
            name: "sub25",
            className: "item-sub",
            children: {
              href: "https://www.bilibili.com/video/BV1jE411R7vg",
              target: "_blank",
              className: "item-sub-item",
              children: [
                {
                  children: <p>AI无感超级会员</p>,
                  name: "text",
                },
              ],
            },
          },
          {
            name: "sub26",
            className: "item-sub",
            children: {
              href: "https://www.bilibili.com/video/BV1By4y1278t",
              target: "_blank",
              className: "item-sub-item",
              children: [
                {
                  children: <p>新零收-智慧零售</p>,
                  name: "text",
                },
              ],
            },
          },
          {
            name: "sub27",
            className: "item-sub",
            children: {
              href: "https://www.bilibili.com/video/BV1kE411q7PH",
              target: "_blank",
              className: "item-sub-item",
              children: [
                {
                  children: <p>人脸支付万能连接线</p>,
                  name: "text",
                },
              ],
            },
          },
        ],
      },
      {
        name: "item3",
        className: "header3-item",
        children: {
          href: "#Content13_0",
          children: [
            {
              children: (
                <span>
                  <p>关于我们</p>
                </span>
              ),
              name: "text",
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: "header3-mobile-menu" },
};
export const Banner50DataSource = {
  wrapper: { className: "home-page-wrapper banner5 l1eqsl45kdf-editor_css" },
  page: { className: "home-page banner5-page" },
  childWrapper: {
    className: "banner5-title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>以人云原生低代码平台</p>
          </span>
        ),
        className: "banner5-title l1ephuyx7sc-editor_css",
      },
      {
        name: "explain",
        className: "banner5-explain",
        children: (
          <span>
            <span>
              <p>做云原生时代最好用的低代码平台</p>
            </span>
          </span>
        ),
      },
      {
        name: "content",
        className: "banner5-content l1epid98ze6-editor_css",
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <p>
                      通过丰富的界面组件拖拉拽，结合企业自有技术平台深度对接的
                      APaaS 应用开发底座，并且使用低代码云开发模式的 FaaS
                      引擎，以 “随想即现、随需而变”
                      的核心价值定位、广泛的应用场景、敏捷高效的应用构建能力和极低的运维成本，加倍提升开发与落地效率，打破传统数字化实践的困境，引领企业数字化转型。
                    </p>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: "button",
        className: "banner5-button-wrapper",
        children: {
          href: "#hezuozixun",
          className: "banner5-button",
          type: "primary",
          children: "开始使用",
        },
      },
    ],
  },
  image: {
    className: "banner5-image",
    children:
      "https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*-wAhRYnWQscAAAAAAAAAAABkARQnAQ",
  },
};
export const Content00DataSource = {
  wrapper: { className: "home-page-wrapper content0-wrapper" },
  page: { className: "home-page content0" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>快速搭建应用系统</p>
          </span>
        ),
      },
      {
        name: "title~l1emuho15ea",
        className: "l1emujasg3o-editor_css",
        children: (
          <span>
            <span>
              <p>
                拖拉拽式构建，业务流程灵活设计，丰富的应用模板，基于业务+数据模型快速实现应用落地
              </p>
            </span>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: "content0-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon l1emjr4ehyf-editor_css",
              children:
                "https://vimiu.oss-cn-shanghai.aliyuncs.com/app/-ebe80133-1799-42cc-8bf6-5ff3b1409e3a.png",
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <span>
                    <span>
                      <p>应用搭建可视化</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          0代码搭建，所见即所得、
                          <span>模型引擎，一键应用生成</span>
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block1",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon l1emn26svr-editor_css",
              children:
                "https://vimiu.oss-cn-shanghai.aliyuncs.com/app/-1a9b9ee9-4b5f-4362-a0bf-3ac6b2e28393.png",
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <p>业务流程自定义</p>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <p>
                    业务规则随心定制、<span>可视化审批流设计</span>
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block2",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon l1emohlid2-editor_css",
              children:
                "https://vimiu.oss-cn-shanghai.aliyuncs.com/app/-36abb61e-9efd-4e37-ae8f-29f9221e3ad0.png",
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <p>应用运维零成本</p>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <span>
                    <p>
                      <span>强大的版本迭代管理能力、</span>
                      无忧的应用托管与运维保障
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Feature50DataSource = {
  wrapper: { className: "home-page-wrapper content7-wrapper" },
  page: { className: "home-page content7" },
  OverPack: {},
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>产品亮点</p>
          </span>
        ),
        className: "title-h1",
      },
      {
        name: "content",
        children: (
          <span>
            <p>基于稳健的云原生技术底座</p>
          </span>
        ),
      },
    ],
  },
  tabsWrapper: { className: "content7-tabs-wrapper" },
  block: {
    children: [
      {
        name: "block0",
        tag: {
          className: "content7-tag",
          text: {
            children: (
              <span>
                <p>应用搭建可视化</p>
              </span>
            ),
            className: "content7-tag-name",
          },
          icon: { children: "laptop" },
        },
        content: {
          className: "content7-content",
          text: {
            className: "content7-text",
            md: 14,
            xs: 24,
            children: (
              <span>
                <span>
                  <h3>0代码搭建，所见即所得</h3>
                  <p>
                    通过拖拽组件与简单配置即可完成基础应用功能设计，配合自定义代码能力，可满足大多数应用开发场景；应用模板与导入导出能力，实现对典型案例及历史应用版本的高效复用，大大降低开发成本，提高项目开发效率。
                  </p>
                  <br />
                  <h3>模型引擎，一键应用生成</h3>
                  <p>
                    面向业务的模型设计引擎，可可视化定义数据处理与流转逻辑，并可一键生成应用页面；结合流程设计引擎，轻松实现业务信息的采集、管理、检索、流转、审批，大幅简化数据管理开发流程；外部数据源接入支持，保障企业信息统一性的同时，实现业务数据的拓展与融合处理；
                  </p>
                  <h3>
                    <br />
                  </h3>
                </span>
              </span>
            ),
          },
          img: {
            className: "content7-img",
            children:
              "https://vimiu.oss-cn-shanghai.aliyuncs.com/app/-ebe80133-1799-42cc-8bf6-5ff3b1409e3a.png",
            md: 10,
            xs: 24,
          },
        },
      },
      {
        name: "block1",
        tag: {
          className: "content7-tag",
          icon: { children: "laptop" },
          text: {
            className: "content7-tag-name",
            children: (
              <span>
                <span>
                  <p>业务流程自定义</p>
                </span>
              </span>
            ),
          },
        },
        content: {
          className: "content7-content",
          text: {
            className: "content7-text",
            md: 14,
            xs: 24,
            children: (
              <span>
                <span>
                  <h3>业务规则随心定制</h3>
                  <p>
                    支持丰富、灵活的应用业务逻辑定义和控制能力，可结合导航、表单向导、按钮组件及强大的代码自定义能力，以及细粒度的数据流转与关联能力，轻松实现复杂的业务逻辑规则定义、流程跳转与数据处理，极大程度地满足了企业实际业务需求。
                  </p>
                  <br />
                  <h3>可视化审批流设计</h3>
                  <p>
                    基于数据模型的流程设计引擎（BPDE），可拖拽式、可视化设计业务审批与管理流程，集合丰富的内置组织结构关系与角色、权限设置体系，轻松应对各种复杂业务与信息的报备、审批、管理、回溯、审计与统计分析需求；
                  </p>
                  <h3>
                    <br />
                  </h3>
                </span>
              </span>
            ),
          },
          img: {
            className: "content7-img",
            md: 10,
            xs: 24,
            children:
              "https://vimiu.oss-cn-shanghai.aliyuncs.com/app/-1a9b9ee9-4b5f-4362-a0bf-3ac6b2e28393.png",
          },
        },
      },
      {
        name: "block2",
        tag: {
          className: "content7-tag",
          text: {
            children: (
              <span>
                <p>应用运维零成本</p>
              </span>
            ),
            className: "content7-tag-name",
          },
          icon: { children: "laptop" },
        },
        content: {
          className: "content7-content",
          text: {
            className: "content7-text",
            md: 14,
            xs: 24,
            children: (
              <span>
                <span>
                  <h3>强大的版本迭代管理能力</h3>
                </span>
                <span>
                  <p>
                    <span>
                      强大的应用迭代开发能力，实现应用持续更新迭代且不影响已发布版本的正常运行，并可实现快速版本回溯，满足不断变化的业务需求，破解SaaS应用高成本需求变化响应难题；多发布环境支持，充分满足应用发布流程与生命周期管理要求
                    </span>
                    。
                  </p>
                  <br />
                  <h3>
                    <p>无忧的应用托管与运维保障</p>
                  </h3>
                  <p>
                    领先的云计算技术、资源与产品能力，爱速搭平台提供安全、可靠的应用发布托管服务；应用间数据安全隔离，多节点资源调度与自动化监测，轻松响应突发访问，免除用户服务器资源投入与人力运维成本；集成丰富的组织关系与角色、权限设置体系，轻松应对各种复杂业务流程与权限分配、审计需求；
                  </p>
                </span>
              </span>
            ),
          },
          img: {
            className: "content7-img",
            md: 10,
            xs: 24,
            children:
              "https://vimiu.oss-cn-shanghai.aliyuncs.com/app/-36abb61e-9efd-4e37-ae8f-29f9221e3ad0.png",
          },
        },
      },
    ],
  },
};
export const Content30DataSource = {
  wrapper: { className: "home-page-wrapper content3-wrapper" },
  page: { className: "home-page content3" },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <span>
              <p>产品优势</p>
            </span>
          </span>
        ),
        className: "title-h1",
      },
      {
        name: "content",
        className: "title-content",
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>
                    领先技术、资源、产品、生态能力深度加持与内部海量真实业务应用验证，打造多项产品优势
                  </p>
                </span>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
  block: {
    className: "content3-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://vimiu.oss-cn-shanghai.aliyuncs.com/app/-dad877d2-b177-4f2f-aa24-4e12610c8196.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <p>更灵活</p>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <span>
                  <p>
                    支持多种应用页面构建形式和数据接驳方式，既可自建数据模型，也可灵活接驳客户已有数据源或对接外部API；支持海量功能组件，并支持自定义代码、系统变量和接口适配能力，灵活度媲美代码开发
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: "block1",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://vimiu.oss-cn-shanghai.aliyuncs.com/app/-6c6758e6-f34f-4a82-9613-99857e9a7eb4.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <span>
                  <p>更智能</p>
                </span>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <p>
                  结合AI技术和丰富的AI产品矩阵，爱速搭将持续集成包括OCR、图像识别、人脸识别等各类AI能力，实现智能化应用与项目的快速落地，赋能企业更简单快速地拥抱AI，体验产业变革红利
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: "block2",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon l1f3vv5cbqe-editor_css",
            children:
              "https://vimiu.oss-cn-shanghai.aliyuncs.com/app/-683cb489-877d-4dd2-97eb-e08c367c78df.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <p>更健壮</p>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <span>
                  <p>
                    基于内部明星产品多年大规模应用实战打磨与孵化，200+部门、3000+应用、8万+用户共同验证，技术架构更健壮、能力建设更完善；前端渲染框架AMIS现已开源，接受更多开发者与用户检验
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: "block3",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://vimiu.oss-cn-shanghai.aliyuncs.com/app/-3dd559aa-2cb1-4ef8-b125-e3ffe8d6dc98.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <p>更强大</p>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <span>
                  <p>
                    依托云深厚的技术基础、超大规模的云计算资源节点、丰富的云产品线和庞大的合作伙伴生态，支持更高速稳定的应用访问，更多样化的云产品能力接入与应用部署方式和更丰富的生态服务
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: "block~l1eng63k8p",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://vimiu.oss-cn-shanghai.aliyuncs.com/app/-8432d6b2-b8e4-492c-a05f-f12af5ef5d97.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <span>
                  <span>
                    <p>移动适配</p>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>
                        一站式快速开发平台，包含了UI移动组件库、移动端常用API、插件化机制、热更新、云端打包等内容。几乎囊括了开发移动应用的所有细节，真正实现拿来即用
                        <br />
                      </p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: "block~l1enjaqx9f7",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://vimiu.oss-cn-shanghai.aliyuncs.com/app/-cef5dd34-6ad9-4d10-8de3-a0a79ff23359.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>FaaS低代码开发</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <span>
                  <p>
                    云开发模式下，提供了Faas
                    的平台服务，通过云函数，云托管也就是容器托管，云存储和云数据库，屏蔽了底层需要管理的冷备热备，网络防护，负载均衡这些后端运维能力
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Feature70DataSource = {
  wrapper: { className: "home-page-wrapper feature7-wrapper" },
  page: { className: "home-page feature7" },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: "feature7-title-wrapper",
    children: [
      {
        name: "title",
        className: "feature7-title-h1",
        children: (
          <span>
            <p>功能特性</p>
          </span>
        ),
      },
      {
        name: "content",
        className: "feature7-title-content",
        children: (
          <span>
            <span>
              <p>更好用的功能</p>
            </span>
          </span>
        ),
      },
    ],
  },
  blockWrapper: {
    className: "feature7-block-wrapper",
    gutter: 24,
    children: [
      {
        md: 6,
        xs: 24,
        name: "block0",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "title",
              className: "feature7-block-title",
              children: (
                <span>
                  <span>
                    <p>多种构建模式</p>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: (
                <span>
                  <p>
                    支持模型、组件、富文本、Markdown和基于模板创建等多种应用页面设计形式
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block1",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "title",
              className: "feature7-block-title",
              children: (
                <span>
                  <p>丰富组件支持</p>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: (
                <span>
                  <p>
                    支持五大类、数十种组件，涵盖交互展现、逻辑处理及服务能力，轻松构建复杂应用
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block2",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "title",
              className: "feature7-block-title",
              children: (
                <span>
                  <span>
                    <span>
                      <p>AI能力集成</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          现已支持图片风控、OCR等AI功能，更多AI能力持续集成中
                        </p>
                        <p>
                          <br />
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block3",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "title",
              className: "feature7-block-title",
              children: (
                <span>
                  <p>模型化数据托管</p>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: (
                <span>
                  <p>
                    支持面向业务描述的数据模型设计与数据托管，快速实现数据处理与交互展现
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block4",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "title",
              className: "feature7-block-title",
              children: (
                <span>
                  <p>外部数据源支持</p>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: (
                <span>
                  <p>
                    支持外部数据库（MySQL等）直接对接至数据模型，实现已有系统的无缝扩展
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block5",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "title",
              className: "feature7-block-title",
              children: (
                <span>
                  <p>API数据接入</p>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: (
                <span>
                  <p>
                    支持Restful
                    API接口适配与数据对接，轻松实现外部数据及应用能力的调用与拓展
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block6",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "title",
              className: "feature7-block-title",
              children: (
                <span>
                  <p>复杂流程设计</p>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: (
                <span>
                  <p>
                    基于数据模型，可轻松定义各类复杂业务处理和审批流程，满足各类业务审批场景
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block7",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "title",
              className: "feature7-block-title",
              children: (
                <span>
                  <p>自定义代码支持</p>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: (
                <span>
                  <p>
                    支持基于Lodash语法的组件级、页面级代码自定义能力，满足更复杂的交互逻辑
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block~l1eo5txtwrk",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "title",
              className: "feature7-block-title",
              children: (
                <span>
                  <span>
                    <p>多环境版本发布</p>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: (
                <span>
                  <p>
                    支持沙盒、测试和正式环境发布；支持持续更新的线上版本迭代发布与历史版本回滚
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block~l1eo5w4y2nk",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "title",
              className: "feature7-block-title",
              children: (
                <span>
                  <span>
                    <p>灵活角色与权限设置</p>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: (
                <span>
                  <span>
                    <p>
                      支持基于部门的多层级角色、用户定义和到应用资源和行为粒度的多维度权限设置
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block~l1eo5x0tckh",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "title",
              className: "feature7-block-title",
              children: (
                <span>
                  <span>
                    <p>应用导入导出</p>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: (
                <span>
                  <span>
                    <p>支持应用的导入导出功能，可实现应用快速复制与传播分享</p>
                    <p>
                      <br />
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block~l1eo5y0gfnu",
        className: "feature7-block",
        children: {
          className: "feature7-block-group l1eo6u0mmja-editor_css",
          children: [
            {
              name: "title",
              className: "feature7-block-title",
              children: (
                <span>
                  <span>
                    <span>
                      <p>私有部署支持</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: (
                <span>
                  <p>
                    支持平台整体私有部署和基于应用的容器化或镜像独立部署方案，满足各种场景需求
                  </p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Feature71DataSource = {
  wrapper: { className: "home-page-wrapper feature7-wrapper" },
  page: { className: "home-page feature7" },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: "feature7-title-wrapper",
    children: [
      {
        name: "title",
        className: "feature7-title-h1",
        children: (
          <span>
            <span>
              <p>使用场景</p>
            </span>
          </span>
        ),
      },
      {
        name: "content",
        className: "feature7-title-content",
        children: (
          <span>
            <p>
              适用于多种不同类型企业的多样化场景企业应用开发、系统项目实施与数字化建设
            </p>
          </span>
        ),
      },
    ],
  },
  blockWrapper: {
    className: "feature7-block-wrapper",
    gutter: 24,
    children: [
      {
        md: 6,
        xs: 24,
        name: "block0",
        className: "feature7-block",
        children: {
          className: "feature7-block-group l1eon8q70v-editor_css",
          children: [
            {
              name: "title",
              className: "feature7-block-title l1eoohvip-editor_css",
              children: (
                <span>
                  <p>产品管理后台配套建设</p>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content l1eoogtyi5e-editor_css",
              children: (
                <span>
                  <p>
                    灵活的数据对接特性，可小时级构建复杂产品服务管理中心，满足产品服务运营管理与快速交付
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block~l1eor1h65pd",
        className: "feature7-block",
        children: {
          className: "feature7-block-group l1eon8q70v-editor_css",
          children: [
            {
              name: "title",
              className: "feature7-block-title l1eoohvip-editor_css",
              children: (
                <span>
                  <span>
                    <p>办公应用搭建与完善</p>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content l1eoogtyi5e-editor_css",
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          可基于BPM、数据模型等能力，根据实际搭建各类适合自身需求的办公应用，并支持与已有系统打通
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block~l1eor23cc7t",
        className: "feature7-block",
        children: {
          className: "feature7-block-group l1eon8q70v-editor_css",
          children: [
            {
              name: "title",
              className: "feature7-block-title l1eoohvip-editor_css",
              children: (
                <span>
                  <span>
                    <p>企业ERP系统建设、迁移与扩展</p>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content l1eoogtyi5e-editor_css",
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        可按需快速构建库管、生产、调度、客服等各业务系统，并接驳打通已有系灵活升级的一体化ERP平台
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block~l1eor3cvfc",
        className: "feature7-block",
        children: {
          className: "feature7-block-group l1eon8q70v-editor_css",
          children: [
            {
              name: "title",
              className: "feature7-block-title l1eoohvip-editor_css",
              children: (
                <span>
                  <span>
                    <p>信息采集、标注与审核</p>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content l1eoogtyi5e-editor_css",
              children: (
                <span>
                  <span>
                    <p>
                      爱速搭可快速构建各类表单类型应用，实现对用户、业务数据的采集、加工处理和汇总分析
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block~l1eor4jbki6",
        className: "feature7-block",
        children: {
          className: "feature7-block-group l1eon8q70v-editor_css",
          children: [
            {
              name: "title",
              className: "feature7-block-title l1eoohvip-editor_css",
              children: (
                <span>
                  <span>
                    <span>
                      <p>IT智能化改造</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content l1eoogtyi5e-editor_css",
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        融合有更多的AI集成特性，可帮助企业快速搭建或改造具备智能化能力的工业应用，实现业务升级
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block~l1eor5stcol",
        className: "feature7-block",
        children: {
          className: "feature7-block-group l1eon8q70v-editor_css",
          children: [
            {
              name: "title",
              className: "feature7-block-title l1eoohvip-editor_css",
              children: (
                <span>
                  <span>
                    <p>客户项目定制实施</p>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content l1eoogtyi5e-editor_css",
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        可根据业务需求快速实现定制化开发，需求变更成本低，更可免除运维烦恼，实现高效交付
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block~l1eor7cao1",
        className: "feature7-block",
        children: {
          className: "feature7-block-group l1eon8q70v-editor_css",
          children: [
            {
              name: "title",
              className: "feature7-block-title l1eoohvip-editor_css",
              children: (
                <span>
                  <span>
                    <p>SaaS软件研发</p>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content l1eoogtyi5e-editor_css",
              children: (
                <span>
                  <span>
                    <p>
                      基于AI、数据集成、可视化和云服务能力，可快速研发输出具备AI特性的企业SaaS应用
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: "block~l1eor8dp7rp",
        className: "feature7-block",
        children: {
          className: "feature7-block-group l1eon8q70v-editor_css",
          children: [
            {
              name: "title",
              className: "feature7-block-title l1eoohvip-editor_css",
              children: (
                <span>
                  <span>
                    <p>数字化中台建设</p>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content l1eoogtyi5e-editor_css",
              children: (
                <span>
                  <span>
                    <p>
                      可与智能云数据中台、AI中台和微服务产品深度融合，联合构建企业数字化中台
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content130DataSource = {
  OverPack: {
    className: "home-page-wrapper content13-wrapper l1et26tsxjf-editor_css",
    playScale: 0.3,
  },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>公司介绍</p>
          </span>
        ),
        className: "title-h1",
      },
      {
        name: "content",
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>
                    上海以人科技有限公司，成立于 2018 年，
                    公司位于上海徐汇，是上海市高新技术企业、双软认证企业。
                  </p>
                  <p>
                    以人科技是一家技术平台服务提供商，通过云原生低代码平台为开发者提供可视化、在线化、移动化、集成化、智能化的应用搭建与服务，为客户低成本构建应用的一体化解决方案，赋能客户信息化系统的高效、高质量建设。
                  </p>
                  <p>
                    以人低代码产品使用了全新的云原生开发应用方式，旨在「做云原生时代最好用的低代码平台」。
                  </p>
                </span>
              </span>
            </span>
          </span>
        ),
        className: "title-content l1et2g41vuc-editor_css",
      },
    ],
  },
};
export const Contact00DataSource = {
  wrapper: {
    className: "home-page-wrapper content10-wrapper l1esmzq83y-editor_css",
  },
  Content: {
    className: "icon-wrapper",
    children: {
      icon: {
        className: "icon",
        children:
          "https://gw.alipayobjects.com/zos/rmsportal/zIUVomgdcKEKcnnQdOzw.svg",
        name: "主要图标",
      },
      iconShadow: {
        className: "icon-shadow",
        children:
          "https://gw.alipayobjects.com/zos/rmsportal/WIePwurYppfVvDNASZRN.svg",
        name: "图标影阴",
      },
      url: { children: "https://gaode.com/place/B0FFHKUY4Z", name: "跳转地址" },
      title: { children: "公司地址", name: "弹框标题" },
      content: { children: "上海市徐汇区田林路130号20号楼", name: "弹框内容" },
    },
  },
};
export const Footer00DataSource = {
  wrapper: {
    className: "home-page-wrapper footer0-wrapper l1eth5qckw-editor_css",
  },
  OverPack: { className: "home-page footer0", playScale: 0.05 },
  copyright: {
    className: "copyright",
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <span>
                  ©2022 以人科技&nbsp;All Rights Reserved&nbsp; &nbsp;&nbsp;
                </span>
              </span>
              沪ICP备18045565号-3
              <span>
                <span>
                  <br />
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
